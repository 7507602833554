import React from "react";
import "./Index.css";
import { Header } from "rsuite";
import CancelOrder from "../buttons/CancelOrder";
import { Link } from "react-router-dom";

function Index({ history }) {
  const handleClick = () => {
    history.push("/");
  };

  return (
    <Header>
      <img
        src="https://strivefootwear.com/media/theme_options/default/Strive-Logo-Black_Orange_1.png"
        height="40"
      />
      <h1 class="app-title">Returns Platform </h1>
      <Link
        to={{
          pathname: "https://strivefootwear.com",
        }}
      />
    </Header>
  );
}

export default Index;
