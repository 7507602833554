import { handleResponse, handleError } from "./apiUtils";
const api_url = process.env.REACT_APP_API_URL;

function extractData(res) {
  return res.json().then((response) => {
    if (response) {
      if (response.items) {
        return response.items;
      }
      return response;
    } else {
      return null;
    }
  });
}

function extractQlData(res) {
  return res.json().then((response) => {
    if (response && response.data) {
      if (response.data.products.items.length > 0) {
        return response.data.products.items[0].price_range.minimum_price
          .final_price.value;
      }
    }
    return null;
  });
}

export function getProductsFromFamily(id, passKey, isUs = false) {
  return fetch(api_url + "/products/family", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + passKey,
    },
    body: JSON.stringify({
      id,
      isUs,
    }),
  })
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}

export function getProductMedia(sku, passKey) {
  return fetch(api_url + "/products/media", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + passKey,
    },
    body: JSON.stringify({
      sku,
    }),
  })
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}
export function getProductItem(sku, passKey) {
  return fetch(api_url + "/products/item", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + passKey,
    },
    body: JSON.stringify({
      sku,
    }),
  })
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}
export function getFinalPrice(sku, store, passKey) {
  return fetch(api_url + "/products/final-price", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + passKey,
    },
    body: JSON.stringify({
      sku,
      store,
    }),
  })
    .then(handleResponse)
    .then(extractQlData)
    .catch(handleError);
}
