import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectStock } from "../../../redux/slices/stock/stockSlice";
import SelectInput from "../../shared/SelectInput";
import TextInputSimple from "../../shared/TextInputSimple";
import { selectIsUs } from "../../../redux/slices/customer/customerSlice";
import "./ProductFinder.css";

function ProductFinder({
  onChange,
  product,
  searchEntry,
  itemId,
  noOptionsAvailable,
}) {
  const [errors] = useState([]);
  const [result, setResult] = useState([]);
  const [options, setOptions] = useState([]);
  const [productsInList, setProductsInList] = useState([]);
  const isUs = useSelector(selectIsUs);
  const stock = useSelector(selectStock);

  useEffect(() => {
    findProduct();
  }, [searchEntry]);

  const findProduct = () => {
    if (searchEntry && searchEntry[itemId] && searchEntry[itemId].length > 3) {
      let items = stock.filter((stockItem) => {
        return String(stockItem.description)
          .toLowerCase()
          .includes(String(searchEntry[itemId]).toLowerCase());
      });
      console.log("items findProduct", items);
      console.log("searchEntry[itemId]", searchEntry[itemId]);

      if (items.length > 1) {
        setResult(items[0].description.split("PAIR")[0]);
        items.map((item) => {
          if (item.freestock > 2) {
            let newOptions = options;
            let newProductsInList = productsInList;
            let sku = item.sku;
            if (isUs) {
              sku = "US" + item.sku;
            }
            if (!newProductsInList.includes(sku)) {
              newOptions.push({
                value: item.description + " / " + sku,
                text: item.description,
              });
              newProductsInList.push(sku);
              setProductsInList(newProductsInList);
              setOptions(newOptions);
            }
          }
        });
      }
    } else {
      setOptions([]);
      setResult([]);
      setProductsInList([]);
    }
  };

  const getSelectInput = () => {
    return options.length > 0 ? (
      <SelectInput
        name="product"
        onChange={(e) => {
          onChange(e, itemId);
        }}
        defaultOption="Please Select New Product"
        value={product[itemId]}
        label=""
        disabled={false}
        options={options}
      />
    ) : null;
  };

  return (
    <>
      <td>
        {noOptionsAvailable ? (
          <p className="no-options-notice">
            No options for this style are available, do you want to exchange for
            a different product?
          </p>
        ) : (
          ""
        )}
        <div className="diffent-product">
          <TextInputSimple
            name="product-search"
            label="Search for product by name and colour"
            value={searchEntry[itemId]}
            onChange={(e) => onChange(e, itemId)}
            error={errors.newProduct}
          />
          {getSelectInput()}
        </div>
      </td>
    </>
  );
}

export default ProductFinder;
