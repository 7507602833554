import React, { useEffect, useState } from "react";
import "./Index.css";
import Checkbox from "../shared/Checkbox";
import Reasons from "./Reasons";
import Type from "./Type";
import Exchange from "./exchange/Index";
import { getProductMedia } from "../../api/productApi";
import { useSelector } from "react-redux";
import { selectReturns } from "../../redux/slices/order/orderSlice";
import { selectToken } from "../../redux/slices/auth/authSlice";
import { Loader, InputNumber } from "rsuite";
import { getProductItem } from "../../api/productApi";

function ItemList({
  order,
  onChange,
  selected,
  errors,
  refundType,
  reason,
  extendedReason,
  size,
  colour,
  product,
  exchangeType,
  searchEntry,
  defaultFileList,
  setDefaultFileList,
  qty,
  onQtyChange,
  setNewValues,
  newValues,
  setMainSku,
  mainSku,
}) {
  const [images, setImages] = useState([]);
  const [itemList, setItemList] = useState([]);

  const returnRecords = useSelector(selectReturns);
  const token = useSelector(selectToken);
  const [productsName, setProductsName] = useState([]);

  useEffect(() => {
    getNewProductName().then((products) => {
      setProductsName(products);
      console.log("products", products);
      getMedia().then((images) => {
        setImages(images);
        if (itemList.length === 0) {
          generateItemList(images, products);
        }
        // if (itemList.length === 0) {
        //   generateItemList(images);
        // }
      });
    });
  }, []);
  useEffect(() => {
    console.log("productsName useeffect", productsName);
  }, [productsName]);

  useEffect(() => {
    console.log("mainSku useeffect", mainSku);
  }, [mainSku]);

  useEffect(() => {
    generateItemList(images, productsName);
  }, [
    images,
    reason,
    extendedReason,
    colour,
    size,
    product,
    exchangeType,
    selected,
    refundType,
    searchEntry,
    returnRecords,
    productsName,
  ]);

  const getColourLabel = (item) => {
    // console.log("getColourLabel item", item);
    let nameArray = item.name.split("-");
    let colour = "";
    if (!item.name.toLowerCase().includes("insole")) {
      for (let name in nameArray) {
        if (
          parseInt(name) != 0 &&
          parseInt(name) != parseInt(nameArray.length - 1)
        ) {
          colour = colour + " " + nameArray[name];
        }
      }
      return colour;
    } else {
      return "";
    }
  };

  // const getColourLabel1 = (item) => {
  //   console.log("getColourLabel item", item);
  //   if (item != undefined) {
  //     let nameArray = item.name.split("-");
  //     let colour = "";
  //     if (!item.name.toLowerCase().includes("insole")) {
  //       for (let name in nameArray) {
  //         if (
  //           parseInt(name) != 0 &&
  //           parseInt(name) != parseInt(nameArray.length - 1)
  //         ) {
  //           colour = colour + " " + nameArray[name];
  //         }
  //       }
  //       return colour;
  //     } else {
  //       return "";
  //     }
  //   } else {
  //     return "";
  //   }
  // };
  const getSizeLabel = (item) => {
    if (item != undefined) {
      if (!item.name.toLowerCase().includes("insole")) {
        return " Size " + item.sku.split("-")[1];
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const formatCurrency = (currency) => {
    switch (currency) {
      case "GBP":
        return "£";
      case "EUR":
        return "€";
      case "USD":
        return "$";
      default:
        return "£";
    }
  };

  const getMedia = () => {
    return new Promise((resolve, reject) => {
      let count = 0;
      let newImages = images;
      if (order && order.items) {
        order.items.map((item, key) => {
          if (item && item.product_type && item.product_type === "simple") {
            let parentSku = item.sku.split("-");
            if (parentSku.length > 0) {
              getProductMedia(parentSku[0], token).then((items) => {
                if (items && items.length > 0) {
                  newImages[item.sku] =
                    "https://strivefootwear.com/media/catalog/product" +
                    items[0].file;
                  setImages(newImages);
                  count = count + 1;
                }
                if (count === order.items.length) {
                  resolve(newImages);
                }
              });
            }
          } else {
            count = count + 1;
            if (count === order.items.length) {
              resolve(newImages);
            }
          }
        });
      }
    });
  };

  const getStatus = (status) => {
    switch (status) {
      case "new":
        return "We are expecting your return of this item";
      case "rejected":
        return "This item was rejected and will be sent back to you";
      case "complete":
        return "Your return has been succesfully processed";
      case "pending":
        return "We have recieved your goods back.";
      case "issue":
        return "We have recieved your goods back.";
      default:
        return "";
    }
  };
  const getNewProductName = () => {
    // let sku = sku.split("-")[0];
    return new Promise((resolve, reject) => {
      if (returnRecords && returnRecords.length > 0) {
        let count = 0;
        let newValuesArray = newValues;
        let newMainSku = mainSku;
        let records = returnRecords.filter((returnRecord) => {
          return returnRecord.type == "Exchange";
        });
        returnRecords.map((returnRecord) => {
          if (returnRecord.type == "Exchange") {
            let old_sku = returnRecord.sku;
            if (returnRecord.original_sku != "NA") {
              old_sku = returnRecord.original_sku;
            }

            console.log("returnRecord new new", returnRecord);
            let sku = returnRecord.new_sku;
            if (
              returnRecord.product_data && // 👈 null and undefined check
              Object.keys(returnRecord.product_data).length != 0
            ) {
              newMainSku[old_sku] = old_sku;
              newValuesArray[old_sku] = returnRecord.product_data;
              // newValuesArray[old_sku].original_sku = old_sku;
              setMainSku(newMainSku);
              setNewValues(newValuesArray);
            }
            // getProductItem(sku.trim(), token).then((result) => {
            //   if (result && result.length > 0 && result[0].price) {
            //     console.log("new product or sku", result[0]);
            //     newValuesArray[old_sku] = result[0];
            //     setNewValues(newValuesArray);
            //     count = count + 1;
            //   }
            count = count + 1;
            if (count === records.length) {
              resolve(newValuesArray);
            }
            // });
          } else {
            resolve([]);
          }
        });
      } else {
        resolve([]);
      }
    });
  };
  function getProductName(item, products) {
    let returnExistsExchange = [];

    if (returnRecords && returnRecords.length > 0) {
      returnExistsExchange = returnRecords.filter((returnRecord) => {
        if (returnRecord.type == "Exchange") {
          let skuToCheck = returnRecord.sku.trim();
          if (returnRecord.original_sku != "NA") {
            skuToCheck = returnRecord.original_sku.trim();
          }
          return item.sku.trim() === skuToCheck;
        } else {
          return false;
        }
      });
    }

    // console.log("returnRecords", returnRecords);
    // console.log("productsName", products);
    // console.log("item.sku", item.sku);
    // console.log("returnExistsExchange.length", returnExistsExchange.length);

    return parseInt(returnExistsExchange.length) > 0 &&
      products.hasOwnProperty(item.sku) ? (
      <span className="product-name exhanging-again">
        {returnExistsExchange[0].qty_to_return +
          " x " +
          products[item.sku].name.split("-")[0] +
          " " +
          getColourLabel(products[item.sku]) +
          getSizeLabel(products[item.sku])}
      </span>
    ) : (
      <span className="product-name">
        {item.qty_ordered +
          " x " +
          item.parent_item.name +
          " " +
          getColourLabel(item) +
          getSizeLabel(item)}
      </span>
    );
  }

  const availableForReturn = (item) => {
    let returnExists = [];

    let returnExistsExchange = [];

    if (returnRecords && returnRecords.length > 0) {
      returnExists = returnRecords.filter((returnRecord) => {
        if (returnRecord.type != "Exchange")
          return item.sku.trim() === returnRecord.sku.trim();
        else return false;
      });
      returnExistsExchange = returnRecords.filter((returnRecord) => {
        if (returnRecord.type == "Exchange") {
          let skuToCheck = returnRecord.sku.trim();
          if (returnRecord.original_sku != "NA") {
            skuToCheck = returnRecord.original_sku.trim();
          }
          if (returnRecord.status != "complete") {
            return item.sku.trim() === skuToCheck;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
    }

    // console.log("returnRecords", returnRecords);
    console.log("returnExists", returnExists);
    // console.log("returnExistsExchange", returnExistsExchange);

    return parseInt(item.qty_refunded) > 0 ||
      parseInt(returnExists.length) > 0 ||
      parseInt(returnExistsExchange.length) > 0 ? (
      <span
        className={
          returnExistsExchange.length > 0
            ? "already-refunded return-status"
            : "already-refunded"
        }
      >
        {returnExistsExchange.length > 0
          ? getStatus(returnExistsExchange[0].status)
          : "Already Refunded"}
      </span>
    ) : (
      <Checkbox
        name={"selected" + item.item_id}
        value={selected[item.item_id]}
        label={""}
        onChange={(e) => onChange(e, item.item_id)}
        error={errors.selected}
      />
    );
  };

  const generateItemList = (images, products) => {
    let items = [];
    if (order && order.items) {
      order.items.map((item, key) => {
        if (item && item.product_type && item.product_type === "simple") {
          // console.log("qty idhar wali", qty);
          console.log("item id", item.item_id);

          items.push(
            <>
              <tr
                key={key}
                className={
                  selected[item.item_id] ? "item-selected-for-return" : ""
                }
              >
                <td>
                  {availableForReturn(item)}
                  {getProductName(item, products)}
                  {/* <span className="product-name">
                    {item.qty_ordered +
                      " x " +
                      productsName[item.sku] +
                      " " +
                      getColourLabel(item) +
                      getSizeLabel(item)}
                  </span> */}
                </td>
                <td>
                  <img
                    src={images[item.sku]}
                    width="75"
                    alt={"product image for " + item.parent_item.name}
                  />
                </td>
                <td>
                  {/* <input
                    type="number"
                    name="qty_to_return"
                    className="form-control"
                    placeholder="Qty to return"
                    onChange={(e) => onChange(e, item.item_id, item)}
                    value={qty[item.item_id]}
                    min="1"
                    max={item.qty_ordered}
                  /> */}
                  <b>Quantity</b>
                  <InputNumber
                    name="qty_to_return"
                    onChange={(value, e) => onQtyChange(value, e, item)}
                    defaultValue={1}
                    max={item.qty_ordered}
                    value={qty[item.item_id]}
                    min={1}
                    size="sm"
                    readOnly
                  />
                </td>
                <td>
                  {formatCurrency(order.order_currency_code) +
                    item.parent_item.price_incl_tax}
                </td>
                <Type
                  refundType={refundType}
                  itemId={item.item_id}
                  onChange={onChange}
                  selected={selected}
                  reason={reason}
                  extendedReason={extendedReason}
                  item={item}
                ></Type>
                <Reasons
                  itemId={item.item_id}
                  reason={reason}
                  onChange={onChange}
                  refundType={refundType}
                  selected={selected}
                  extendedReason={extendedReason}
                  returnType={refundType}
                  defaultFileList={defaultFileList}
                  setDefaultFileList={setDefaultFileList}
                ></Reasons>
                <Exchange
                  itemId={item.item_id}
                  family={item.parent_item.name}
                  colour={colour}
                  size={size}
                  onChange={onChange}
                  refundType={refundType}
                  selected={selected}
                  product={product}
                  exchangeType={exchangeType}
                  searchEntry={searchEntry}
                  defaultFileList={defaultFileList}
                  setDefaultFileList={setDefaultFileList}
                ></Exchange>
              </tr>
            </>
          );
        }
      });
      setItemList(items);
    }
  };

  return order && order.items ? itemList : <Loader />;
}

export default ItemList;
