import { createSlice } from "@reduxjs/toolkit";
import { getCustomerToken } from "../../../api/authApi";

const key = process.env.REACT_APP_SECRET_KEY_REGEGB;

export const initialState = {
  token: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const { setToken } = authSlice.actions;

export const generateToken = () => (dispatch) => {
  return getCustomerToken(key).then((result) => {
    if (result && result.success) {
      dispatch(setToken(result.token));
      return result;
    } else {
      return { success: false };
    }
  });
};

export const selectToken = (state) =>
  state.auth && state.auth.token ? state.auth.token : null;

export default authSlice.reducer;
