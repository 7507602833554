import { handleResponse, handleError } from "./apiUtils";
const api_url = process.env.REACT_APP_AUTH_URL;

let header = new Headers();

header.append("Content-Type", "application/json");

function extractData(res) {
  return res.json().then((response) => {
    console.log(response);
    if (response) {
      return response;
    } else {
      return null;
    }
  });
}

export function getCustomerToken(key) {
  return fetch(api_url + "/auth", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      key,
    }),
  })
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}

export function getCSRF() {
  return fetch(api_url + "/getCSRFToken", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}
