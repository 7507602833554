import React, { useEffect, useState } from "react";
import "./Index.css";
import EmailForm from "./Email";
import OrderNumberForm from "./OrderNumber";
import { useSelector, useDispatch } from "react-redux";
import {
  selectOrderNumber,
  setOrderNumber,
  setStoreId,
  setEmail,
  clearCustomer,
  selectEmail,
  setCustomerId,
  setOrderId,
  setIsUs,
} from "../../redux/slices/customer/customerSlice";
import {
  clearOrder,
  getItems,
  getReturnRecords,
  selectOrder,
  setComfortGurantee,
  setDate,
} from "../../redux/slices/order/orderSlice";
import { toast } from "react-toastify";
import Spinner from "../shared/Spinner";
import { setStepStatus } from "../../redux/slices/step/stepSlice";
import { setTitle } from "../../redux/slices/title/titleSlice";
import { generateToken, selectToken } from "../../redux/slices/auth/authSlice";
import { getCSRF } from "../../api/authApi";

function Customer({ history, location }) {
  const orderNumber = useSelector(selectOrderNumber);
  const order = useSelector(selectOrder);
  const email = useSelector(selectEmail);
  const token = useSelector(selectToken);

  const [errors] = useState({});
  const [emailEntry, setEmailEntry] = useState(email);
  const [orderNumberEntry, setOrderNumberEntry] = useState(orderNumber);
  const [loading, setLoading] = useState(false);
  const [canOnlyReportFaulty] = useState(false);
  const [csrf, setCsrf] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    getCSRF().then((res) => {
      setCsrf(res.CSRFToken);
    });

    if (!token || token === "") {
      dispatch(generateToken()).then((result) => {
        if (result && result.success) {
          toast.error("authenticated");
        } else {
          toast.error("Cannot authenticate");
        }
      });
    }

    if (order && emailEntry) {
      history.push("/return");
    } else {
      let params = location.search;
      params = params.split("&");
      for (let param in params) {
        params[param] = params[param].split("=");
      }
      let email = "";
      let orderNumber = "";
      for (let param in params) {
        if (params[param][0].includes("email")) {
          setLoading(true);
          email = params[param][1];
          setEmailEntry(email);
          dispatch(setEmail(email));
        }
        if (params[param][0].includes("orderNo")) {
          orderNumber = params[param][1];
          setOrderNumberEntry(orderNumber);
          dispatch(setOrderNumber(orderNumber));
          // eslint-disable-next-line no-loop-func
          dispatch(getItems(orderNumber, token, csrf)).then((order) => {
            if (order && order.payload.length > 0) {
              let isUs = false;
              if (order.payload[0].store_id == 5) {
                isUs = true;
              }
              dispatch(setIsUs(isUs));
              dispatch(setStoreId(order.payload[0].store_id));
              let validDate = validateOrderDate(order);
              if (validDate) {
                validateEmailAddress(order.payload[0], email);
              } else {
                toast.error(
                  "Sorry, exchange / refund is not possible on this order"
                );
              }
            } else {
              toast.error("Invalid Order Number");
            }
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (orderNumber) {
      dispatch(setTitle("Creating return for order #" + orderNumber));
    } else {
      dispatch(setTitle("Create a Return"));
    }
  }, [orderNumber]);

  const validateEmailAddress = (order, email) => {
    if (
      order &&
      Object.keys(order).length > 0 &&
      order.customer_email &&
      order.customer_id
    ) {
      if (order.customer_email === email) {
        dispatch(setEmail(email));
        toast.success("Order Loaded");
        validateComfortGurantee(order);
        if (canOnlyReportFaulty) {
          dispatch(setDate(true));
        }
        dispatch(setStepStatus(1));
        dispatch(setCustomerId(order.customer_id));
        dispatch(setOrderId(order.items[0].order_id));
        dispatch(getReturnRecords(orderNumber, token, csrf));
        setTimeout(() => {
          history.push("/return");
        }, 2000);
        // history.push("/return");
      } else {
        setEmailEntry("");
        setOrderNumberEntry("");
        toast.error("No Order Found");
        dispatch(clearOrder());
        dispatch(clearCustomer());
        setLoading(false);
      }
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case "new":
        return "We are expecting your return of this item";
      case "rejected":
        return "This item was rejected and will be sent back to you";
      case "complete":
        return "Your return has been succesfully processed";
      case "pending":
        return "We have recieved your goods back.";
      case "issue":
        return "We have recieved your goods back.";
      default:
        return "";
    }
  };
  const validateOrderDate = (order) => {
    console.log("order", order);
    if (order[0] && order[0].created_at) {
      let splitDate = order[0].created_at.split(" ")[0].split("-");
      let createdAt = new Date(
        parseInt(splitDate[0]) +
          "-" +
          parseInt(splitDate[1], 10) +
          "-" +
          parseInt(splitDate[2])
      );
      // console.log("createdAt", createdAt);

      let sixMonthsLater = new Date(
        parseInt(splitDate[0]) +
          "-" +
          parseInt(splitDate[1], 10) +
          "-" +
          parseInt(splitDate[2])
      );
      // let sixMonthsLater = new Date(
      //   parseInt(splitDate[0]) +
      //     "-" +
      //     (parseInt(splitDate[1], 10) + 6) +
      //     "-" +
      //     parseInt(splitDate[2])
      // );
      sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);
      // console.log("sixMonthsLater", sixMonthsLater);

      let thirtyDaysFromOrder = new Date(
        parseInt(splitDate[0]) +
          "-" +
          (parseInt(splitDate[1], 10) + 1) +
          "-" +
          parseInt(splitDate[2])
      );
      // console.log("thirtyDaysFromOrder", thirtyDaysFromOrder);

      let today = new Date();
      // console.log("today", today);

      if (createdAt < sixMonthsLater) {
        return true;
      } else if (today > thirtyDaysFromOrder) {
        return true;
      }
    }
    return false;
  };

  const validateComfortGurantee = (order) => {
    if (order && order.discount_description) {
      let discountDescription = order.discount_description;

      if (discountDescription === "30 Day Comfort Guarantee") {
        dispatch(setComfortGurantee(true));
      }
    }
  };

  const validateOrderNumber = () => {
    setLoading(true);
    dispatch(getItems(orderNumberEntry, token, csrf)).then((order) => {
      if (order && order.payload.length > 0) {
        dispatch(setOrderNumber(orderNumberEntry));
        let isUs = false;
        if (order.payload[0].store_id == 5) {
          isUs = true;
        }
        dispatch(setIsUs(isUs));
        dispatch(setStoreId(order.payload[0].store_id));
        console.log("order data", order);
        let validDate = validateOrderDate(order.payload);
        if (!validDate) {
          toast.error("Sorry, exchange / refund is not possible on this order");
          dispatch(clearOrder());
          dispatch(clearCustomer());
        }
        setLoading(false);
      } else {
        toast.error("No Order Found");
        setLoading(false);
      }
    });
  };

  const showForm = () => {
    return order !== null && Object.keys(order).length > 0 ? (
      <>
        <h3>Enter the email address on the order</h3>
        <EmailForm
          onChange={(value) => setEmailEntry(value)}
          orderNumber={orderNumber}
          email={emailEntry}
          errors={errors}
          onSave={() => validateEmailAddress(order, emailEntry)}
        />
      </>
    ) : (
      <>
        <OrderNumberForm
          onChange={(value) => {
            setOrderNumberEntry(value);
          }}
          orderNumber={orderNumberEntry}
          onSave={() => validateOrderNumber()}
          errors={errors}
        />
      </>
    );
  };

  return loading ? (
    <>
      <div className="content customer">
        <div className="inner-content">
          <Spinner />
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="content customer">
        <div className="inner-content">{showForm()}</div>
      </div>
    </>
  );
}

export default Customer;
