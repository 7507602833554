import React from "react";
import { useSelector } from "react-redux";
import { selectOrder } from "../../redux/slices/order/orderSlice";
import {
  selectExtendedReasons,
  selectReasons,
} from "../../redux/slices/reason/reasonSlice";
import { selectReturnItems } from "../../redux/slices/return/returnSlice";
import { getProductItem } from "../../api/productApi";
import { selectToken } from "../../redux/slices/auth/authSlice";
import "./ItemList.css";
import { selectReturns } from "../../redux/slices/order/orderSlice";

function ItemList(setNewValues, newValues) {
  const order = useSelector(selectOrder);
  const returnItems = useSelector(selectReturnItems);
  const extendedReasons = useSelector(selectExtendedReasons);
  const reasons = useSelector(selectReasons);
  const token = useSelector(selectToken);
  const returnRecords = useSelector(selectReturns);

  const formatCurrency = (currency) => {
    switch (currency) {
      case "GBP":
        return "£";
      case "EUR":
        return "€";
      case "USD":
        return "$";
      default:
        return "£";
    }
  };

  const formatReason = (reasonId) => {
    let selectedReason = reasons.filter((reason) => {
      return parseInt(reason.Id) === parseInt(reasonId);
    });
    return selectedReason[0].label;
  };

  const formatExtendedReason = (reasonId, extendedReasonId) => {
    if (
      extendedReasonId &&
      extendedReasonId !== undefined &&
      parseInt(reasonId) !== 1
    ) {
      let selectedReason = extendedReasons.filter((reason) => {
        return parseInt(reason.id) === parseInt(extendedReasonId);
      });

      return selectedReason[0].label;
    }
    if (parseInt(reasonId) === 1) {
      return "(" + extendedReasonId + ")";
    }
    return "";
  };

  const getItem = (itemId) => {
    let currentItem = order.items.filter((item) => {
      return parseInt(item.item_id) == parseInt(itemId);
    });
    if (currentItem && currentItem.length > 0) {
      return currentItem[0];
    }
  };

  const getColourLabel = (item) => {
    let nameArray = item.name.split("-");
    let colour = "";
    if (!item.name.toLowerCase().includes("insole")) {
      for (let name in nameArray) {
        if (
          parseInt(name) != 0 &&
          parseInt(name) != parseInt(nameArray.length - 1)
        ) {
          colour = colour + " " + nameArray[name];
        }
      }
      return colour;
    } else {
      return "";
    }
  };

  const getSizeLabel = (item) => {
    if (!item.name.toLowerCase().includes("insole")) {
      return " Size " + item.sku.split("-")[1];
    } else {
      return "";
    }
  };

  const itemHasColour = (name, item) => {
    console.log(name);
    return !name.toLowerCase().includes("insole")
      ? item.colour.split("/")[0].toUpperCase()
      : "";
  };

  let itemList = [];
  if (returnItems) {
    returnItems.map((item, key) => {
      let itemData = getItem(item.itemId);
      // getNewItemPrice(item);
      itemList.push(
        <>
          <tr>
            <th>Type</th>
            <th>Style</th>
            <th>Value</th>
            <th>Reason</th>
            <th>{item.type !== "Refund" ? "New Item" : null}</th>
          </tr>
          <tr key={key}>
            <td>{item.type}</td>
            <td>
              {itemData.qty_ordered +
                " x " +
                // itemData.parent_item.name +
                item.oldItem.name +
                " " +
                getColourLabel(item.oldItem) +
                " " +
                getSizeLabel(item.oldItem)}
            </td>
            <td>
              {formatCurrency(order.order_currency_code) +
                itemData.parent_item.price_incl_tax}
            </td>
            <td>
              {formatReason(item.reason) +
                " " +
                formatExtendedReason(item.reason, item.extendedReason)}
            </td>
            {item.type === "Exchange" ? (
              <td>
                {parseInt(item.exchangeType) === 1 && !item.searchEntry
                  ? (item.exchangeType == 1
                      ? item.oldItem.name
                      : itemData.parent_item.name.toUpperCase()) +
                    " " +
                    itemHasColour(itemData.parent_item.name, item) +
                    " UK " +
                    item.size.split("/")[0]
                  : item.newProduct.split("/")[0]}
              </td>
            ) : null}
            {parseInt(item.reason) === 1 ? (
              <td class="faulty-grant-notice">
                Note: As this item is faulty, your return request will need to
                be granted before you send back. You will be notified via email
                if the return is granted.
              </td>
            ) : (
              ""
            )}
          </tr>
        </>
      );
    });
  }
  return itemList;
}

export default ItemList;
