import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import PageNotFound from "../notFound/Index.js";
import Customer from "../customer/Index.js";
import Return from "../return/Index.js";
import Confirm from "../confirm/Index.js";
import Success from "../success/Index.js";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./Routes.css";

function Container({ location }) {
  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        timeout={{ enter: 300, exit: 300 }}
        classNames="fade"
      >
        <Switch location={location}>
          <Route exact path="/" component={Customer} />
          <Route exact path="/return" component={Return} />
          <Route exact path="/confirm" component={Confirm} />
          <Route exact path="/success" component={Success} />
          <Route component={PageNotFound} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default withRouter(Container);
